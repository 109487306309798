.main-agents {
    .topupperleft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

        .agentscard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px;
            background: #FAFCFF;
            border: 1px solid #EDEFF2;
            border-radius: 10px;
            gap: 20px;
            width: 50%;

            .upperagentscard {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .inneragentscard {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    .colorone {
                        background: rgba(255, 0, 131, 0.3);
                    }

                    .colortwo {
                        background: rgba(151, 71, 255, 0.3);
                    }

                    .inneragentsimg {
                        border-radius: 110px;
                        height: 40px;
                        width: 40px;
                        padding: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: cover;
                        object-position: center;

                        .agentsimg {
                            object-fit: contain;
                            object-position: center;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inneragentscardtext {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        letter-spacing: 0.02em;
                        color: #7F8486;
                    }
                }

                .addagents {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    background-color: transparent;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: 0.02em;
                    color: #FF0083;
                    width: 52px;
                    height: 20px;

                    .addagentsimg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .inneragentscardnumber {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 21px;
                letter-spacing: 0.02em;
                color: #2D3436;
            }
        }
    }
}

@media (max-width:600px) {
    .main-agents .topupperleft {
        flex-direction: column;
    }

    .main-agents .topupperleft .agentscard {
        width: 100%;
    }
}