.main-login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin-left: 15px;
    margin-right: 15px;

    .innerlogin {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px;
        width: 580px;
        background: #FFFFFF;
        border: 1.5px solid #DFE6E9;
        border-radius: 15px;

        .logo {
            margin: 0 auto;
            padding-bottom: 20px;
        }

        .inputpara {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            color: #2D3436;
            margin-bottom: 10px;
        }

        .logininput {
            border: 1px solid rgba(255, 0, 131, 0.1);
            border-radius: 5px;
            width: 100%;
            padding: 15px;
            margin-bottom: 20px;
        }

        .common-btn {
            width: 100%;
            padding: 15px;
            font-weight: 600;
            font-size: 14px;
            line-height: 27px;
            cursor: pointer;
        }
    }
}

// newflow
.main-login-inner {
    padding-top: 10px;
    padding-bottom: 10px;
}

.newflow-box {
    padding-top: 50px;
    padding-bottom: 40px;

    h4 {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 110%;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 15px;
    }

    p {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #A3A3A3;
    }
}

.remove-forgot-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 41px;
    align-items: center;
    justify-content: space-between;

    .remember-me-check-text {
        .checkboxmain {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;

            .checkboxpara {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 110%;
                color: #9d9d9d;
            }

            .checkbox {
                position: relative;
                display: flex;
                align-items: center;
                gap: 4px;
                cursor: pointer;
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
                    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            }

            .checkbox input {
                position: absolute;
                width: 0;
                left: 50px;
                height: 0;
                opacity: 0;
                cursor: pointer;
            }

            .checkbox .checkmark {
                position: relative;
                display: block;
                top: 0;
                left: 0;
                width: 19px;
                height: 19px;
                border: 1.5px solid #2c2c2c;
                border-radius: 4px;
                transition: all 0.2s ease;
            }

            .checkbox input:checked~.checkmark {
                background: #FF0083;
                border: 1.5px solid transparent;
            }

            .checkbox .checkmark::after {
                position: absolute;
                display: block;
                content: "";
                left: 50%;
                top: 41%;
                width: 4.5px;
                height: 10px;
                border: 2px solid #FFFFFF;
                border-width: 0 2px 2px 0;
                transform: translate(-50%, -50%) rotate(45deg);
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -moz-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                opacity: 0;
                transition: all 0.2s ease;
            }

            .checkbox input:checked~.checkmark::after {
                opacity: 1;
                transition: all 0.2s ease;
            }
        }

        p {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #151515;
        }
    }

    .forgot-wrapper {
        .forgot-text {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-decoration-line: underline !important;
            color: #FF0083;
        }
    }
}

.input-box-wrapper {
    position: relative;
    width: 100%;

    input {
        border: 1.5px solid #EAEAEA !important;
        border-radius: 10px !important;
    }

    .input-icon {
        position: absolute;
        right: 10px;
        top: 14px;
    }
}

// Create New Password
.passwoard-limits {
    margin-top: 40px;
    margin-bottom: 40px;

    h4 {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #151515;
        margin-bottom: 6px;
    }

    .list-item-color {
        color: #A3A3A3;
    }

    .list-item-color-red {
        color: #FE0000;
    }

    .list-item-color-green {
        color: #3EC74C;
    }

    p {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .passwoard-list-item-dot {
            width: 6px;
            height: 6px;
            background: #FF0083;
            border-radius: 100%;
            margin-right: 6px;
        }

        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }
}

// success

.successImg-box {
    text-align: center;
    width: 100%;
    padding-bottom: 40px;
    padding-top: 50px;
}

.forgot-pass-wrapper-box{
    width: 100%;
    text-align: left !important;
    padding-bottom: 40px;

    .registered-text{
        margin-bottom: 40px;
    }
}

@media (max-width:600px) {
    .main-login .innerlogin {
        width: 100%;
    }
}