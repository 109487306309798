.main-dashboard {
    .topupperleft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

        .homecard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px;
            background: #fafcff;
            border: 1px solid #edeff2;
            border-radius: 10px;
            gap: 20px;
            width: 33%;

            .upperhomecard {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .innerhomecard {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    .colorone {
                        background: rgba(255, 0, 131, 0.3);
                    }
                    .colortwo {
                        background: rgba(151, 71, 255, 0.3);
                    }
                    .colorthree {
                        background: rgba(255, 86, 82, 0.3);
                    }

                    .inneraffiliatesimg {
                        border-radius: 110px;
                        height: 40px;
                        width: 40px;
                        padding: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: cover;
                        object-position: center;

                        .affiliatesimg {
                            object-fit: contain;
                            object-position: center;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .innerhomecardtext {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        letter-spacing: 0.02em;
                        color: #7f8486;
                    }
                }

                .addaffiliates {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    background-color: transparent;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: 0.02em;
                    color: #ff0083;
                    width: 52px;
                    height: 20px;

                    .addaffiliatesimg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .innerhomecardnumber {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 21px;
                letter-spacing: 0.02em;
                color: #2d3436;
            }
        }
    }

    .maingraphsquare {
        display: flex;
        align-items: center;
        gap: 20px;

        .graph {
            position: relative;
            width: 68%;
            border: 1px solid #dfe6e9;
            border-radius: 16px;
            padding: 30px 24px 14px 10px;
            height: 395px;
            canvas{
                height: 100% !important;
                margin-top: 25px;
                padding-bottom: 25px;
            }
            .graphimg {
                width: 100%;
                height: 344px;
            }
            .revenue_heading_calender {
                h3 {
                    left: 44px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    position: absolute;
                    letter-spacing: 0.02em;
                    top: 30px;
                }
                .dropdown-toggle {
                    background: #ffffff;
                    border: 1.5px solid #f2f2f2;
                    border-radius: 8px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                    color: #151515;
                    margin: 0 15px;
                }
                .dropdown-menu {
                    // width: 100%;
                    a {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 120%;
                        color: #151515;
                        &:hover {
                            background-color: #ff0083 !important;
                            color: #fff !important;
                        }
                    }
                    .calender a:hover {
                        background-color: #fff !important;
                        color: #fff !important;
                    }
                }
                .calenderFilter {
                    top: 16px;
                    position: absolute;
                    right: 42px;
                    background: #ffffff;
                    border: 1px solid #dfe6e9;
                    border-radius: 7px;
                    padding: 9px;
                    justify-content: space-between;
                    align-items: center;
                    display: flex;
                    width: 80px;
                    p {
                        word-break: unset !important;
                        padding-right: 10px;
                    }
                    display: flex;
                }
            }
        }

        .totals {
            display: flex;
            flex-direction: column;
            width: 33%;
            gap: 20px;

            .totalcard {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 15px 22px;
                background: #ffffff;
                border: 1px solid #dfe6e9;
                border-radius: 8px;
                gap: 15px;
                height: 187px;

                .totalcardimg {
                    background: #ff0083;
                    width: 44px;
                    height: 44px;
                    object-fit: cover;
                    object-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 110px;
                    padding: 10px;

                    .totalcardinnerimg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .totalcardhead {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 21px;
                    letter-spacing: 0.02em;
                    color: #2d3436;
                }

                .totalcardtext {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    letter-spacing: 0.02em;
                    color: #7f8486;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .main-dashboard .topupperleft {
        flex-direction: column;
        margin-bottom: 20px;
    }
    .main-dashboard .topupperleft .homecard {
        width: 100%;
    }
    .main-dashboard .maingraphsquare {
        flex-direction: column-reverse;
    }
    .main-dashboard .maingraphsquare .totals {
        width: 100%;
    }
    .main-dashboard .maingraphsquare .graph {
        width: 100%;
        .graphimg {
            height: unset;
        }
    }
}

.addaffiliatemodal {
    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-content {
            background: #ffffff;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
            border-radius: 7px;
            max-width: 416px;
            width: 100%;

            .modal-header {
                box-shadow: inset 0px -1px 0px #dfe6e9;
                border: none;
                padding: 16px 24px;

                .h4 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    color: #111314;
                }

                .btn-close {
                    background: url("../../../src/assets/modalcloseimg.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: absolute;
                    top: 19px;
                    right: 24px;
                    width: 14px;
                    height: 14px;
                }
            }

            .modal-body {
                padding: 0px;

                .modalinputs {
                    padding: 24px 24px 8px;

                    .addinput {
                        .addinputpara {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 16px;
                            color: #2d3436;
                            margin-bottom: 8px;
                        }

                        input {
                            background: #ffffff;
                            border: 1px solid #dfe6e9;
                            border-radius: 7px;
                            width: 100%;
                            padding: 12px;
                            margin-bottom: 16px;

                            &::placeholder {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 16px;
                                color: #a0a1a1;
                            }
                        }
                    }
                }

                .addaffiliatesbtn {
                    display: flex;
                    align-items: center;
                    padding: 24px;
                    gap: 10px;
                    box-shadow: inset 0px 1px 0px #dfe6e9;

                    .white-btn {
                        padding: 12px 24px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        width: 50%;
                    }

                    .common-btn {
                        padding: 12px 24px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        width: 50%;
                    }
                }
            }
        }
    }
}

.bottom-graph-main {
    margin-top: 30px;
    h6 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #2d3436;
        margin-bottom: 10px;
    }
}

.errorMessage {
    position: relative;
    top: -17px;
    color: red;
    font-size: 13px;
}
.cNPointer {
    cursor: not-allowed;
}
.bottom-graph-main h6 span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2d3436;
    margin-bottom: 10px;
}
.classCalender {
    position: absolute;
    right: 38px;
    top: 18.5px;
}
.main-dashboard .maingraphsquare .graph .revenue_heading_calender .classCalender a:hover {
    background-color: #fff !important;
    color: #000 !important;
}

@media (max-width:768px){
    .main-dashboard .maingraphsquare .graph{
        height: unset;
    }
    .main-dashboard .maingraphsquare .graph canvas{
        height: unset !important;
        padding-bottom: 0px ;
    }
    .main-dashboard .maingraphsquare .graph .revenue_heading_calender .classCalender .dropdown-menu{
        left: -300px;
    }
}

@media (max-width:425px){
    .main-dashboard .maingraphsquare .graph .revenue_heading_calender .classCalender .dropdown-menu{
        left: -263px;
    }
}