.main-affiliates {
    .topupperleft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

        .affiliatescard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px;
            background: #FAFCFF;
            border: 1px solid #EDEFF2;
            border-radius: 10px;
            gap: 20px;
            width: 50%;

            .upperaffiliatescard {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .inneraffiliatescard {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    .colorone {
                        background: rgba(255, 0, 131, 0.3);
                    }

                    .colortwo {
                        background: rgba(151, 71, 255, 0.3);
                    }

                    .inneraffiliatesimg {
                        border-radius: 110px;
                        height: 40px;
                        width: 40px;
                        padding: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: cover;
                        object-position: center;

                        .affiliatesimg {
                            object-fit: contain;
                            object-position: center;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inneraffiliatescardtext {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        letter-spacing: 0.02em;
                        color: #7F8486;
                    }
                }

                .addaffiliates {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    background-color: transparent;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: 0.02em;
                    color: #FF0083;
                    width: 52px;
                    height: 20px;

                    .addaffiliatesimg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .inneraffiliatescardnumber {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 21px;
                letter-spacing: 0.02em;
                color: #2D3436;
            }
        }
    }
}

@media (max-width:600px) {
    .main-affiliates .topupperleft {
        flex-direction: column;
    }

    .main-affiliates .topupperleft .affiliatescard {
        width: 100%;
    }
}



// affilatedetailsection


.affiliatesdetails {
    .upperheadings {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 30px;

        .affiliatesdetailheading {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #2D3436;
        }
    }

    .affiliatesinformation {
        .affiliatesinformationhead {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #2D3436;
            margin-bottom: 10px;
        }

        .affiliatesinformationmain {
            display: flex;
            flex-direction: column;
            padding: 20px;
            gap: 28px;
            background: #FFFFFF;
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
            border-radius: 7px;

            .affiliatesinformationupper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .ainformationupperleft {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 20px;
                    width: 50%;

                    .infohead {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        color: #2D3436;
                    }

                    .infopara {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 16px;
                        color: #2D3436;
                    }
                }

                .ainformationupperright {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 20px;
                    width: 50%;

                    .infohead {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        color: #2D3436;
                    }

                    .infopara {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 16px;
                        color: #2D3436;
                    }
                }
            }
        }
    }

    .topupperleft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        .affiliatescard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px;
            background: #FAFCFF;
            border: 1px solid #EDEFF2;
            border-radius: 10px;
            gap: 20px;
            width: 50%;

            .upperaffiliatescard {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .inneraffiliatescard {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    .colorone {
                        background: rgba(255, 0, 131, 0.3);
                    }

                    .colortwo {
                        background: rgba(151, 71, 255, 0.3);
                    }

                    .inneraffiliatesimg {
                        border-radius: 110px;
                        height: 40px;
                        width: 40px;
                        padding: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: cover;
                        object-position: center;

                        .affiliatesimg {
                            object-fit: contain;
                            object-position: center;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .inneraffiliatescardtext {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        letter-spacing: 0.02em;
                        color: #7F8486;
                    }
                }

                .addaffiliates {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    background-color: transparent;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: 0.02em;
                    color: #FF0083;
                    width: 52px;
                    height: 20px;

                    .addaffiliatesimg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .inneraffiliatescardnumber {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 21px;
                letter-spacing: 0.02em;
                color: #2D3436;
            }
        }
    }
}

@media (max-width:768px) {
    .affiliatesdetails .topupperleft {
        flex-direction: column;
    }

    .affiliatesdetails .topupperleft .affiliatescard {
        width: 100%;
    }

    .affiliatesdetails .affiliatesinformation .affiliatesinformationmain .affiliatesinformationupper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .affiliatesdetails .affiliatesinformation .affiliatesinformationmain .affiliatesinformationupper .ainformationupperleft {
        gap: 10px;
        margin-bottom: 20px;
    }

    .affiliatesdetails .affiliatesinformation .affiliatesinformationmain .affiliatesinformationupper .ainformationupperright {
        gap: 10px;
        margin-bottom: 20px;
    }

    .affiliatesdetails .affiliatesinformation .affiliatesinformationmain {
        gap: 0px;
    }
}

@media (max-width:600px) {
    .affiliatesdetails .affiliatesinformation .affiliatesinformationmain .affiliatesinformationupper .ainformationupperright .infopara {
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}