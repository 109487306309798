.datatable{
    .table-responsivecolorbg{
        background: #FFFFFF;
        border-radius: 7px;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
        padding: 30px 20px;
    }
    .upper-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span{
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: #151515;
            }
            .dropdown-toggle{
                background: #FFFFFF;
                border: 1.5px solid #F2F2F2;
                border-radius: 8px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: #151515;
                margin: 0 15px;
            }
            .dropdown{
                .dropdown-menu{
                    width: 100%;
                    a{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 120%;
                        color: #151515;
                        &:hover{
                            background-color: #FF0083 !important;
                            color: #fff !important;
                        }
                    }
                }
            }
        }
        .right{
            .option-field{
                display: flex;
                align-items: center;
                justify-content: center;
                h6{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                    color: #151515;
                    margin-right: 15px;
                }
                input{
                    background: #FFFFFF;
                    border: 1px solid #DFE6E9;
                    border-radius: 7px;
                    max-width: 201px;
                    width: 100%;
                    height: 48px;
                }
            }
            
        }
    }
    th{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #2D3436;
        vertical-align: middle !important;
        border: none;
        padding: 15px 0;
        position: relative;
        white-space: nowrap;
    }
    td{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: #2D3436;
        vertical-align: middle !important;
        border-top: 1px solid #F2F2F2;
        padding: 0;
        padding: 30px 0;
        white-space: nowrap;
    }
    .footer-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-f{
            h6{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #767676;
            }
        }
        .right-f{
            .page-link{
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
                &.active{
                    background: #FF0083;
                    border-radius: 5px;
                    color: #fff;
                }
            }
            .page-item:first-child .page-link{
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
            }
        }
    }
}

.filter-btn{
    display: flex;
    flex-direction: column;
    width: 11px;
    margin-left: 10px;
}

.parent-tag{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (max-width:600px){
    .datatable {
        td{
            text-align: center;
        }
        th{
            text-align: center;
        }
    }
    .datatable .upper-content{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
    }
    .datatable .footer-content{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
}
.cPointer{
    cursor: pointer;
}