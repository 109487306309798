.sidebar {
  position: fixed;
  height: 100vh;

  .sidebar-wrapper {

    .modal12 {

      .mdc12 {
        border-radius: 12px;
        background: #181936;

        .head12 {
          background: #242545;
          border-radius: 12px 12px 0px 0px;
          border: none;
          padding: 22px !important;

          .modal-title {
            text-align: center;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 42px;
            color: #FFFFFF;
          }

          .shut12 {
            background: #1FA5FF;
            padding: 5px 9px;
            margin-top: 1px;
            border-radius: 50px;
          }
        }

        .mdbody12 {
          background: #181936;
          padding: 50px !important;

          .form-group {
            .text123 {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #FFFFFF !important;
            }

            .form-control {
              background: #1E1F3D;
              border: 1px solid #2C2D49;
              border-radius: 8px;
            }
          }
        }

        .footf11 {
          background: #181936;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;

          .btn12 {
            width: 110px;
            height: 54px;
            background: #1FA5FF;
            box-shadow: 0px 3px 30px rgba(31, 165, 255, 0.2);
            border-radius: 48px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
          }

          .canclbtn {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-decoration: underline;
            color: #1FA5FF;

          }
        }

      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 100%;
      color: #A9ABAD !important;
    }

    .active {
      background: #F2F7FF;
      color: #FF0083;
      border-left: 5px solid #FF0083;

      p {
        color: #FF0083 !important;
      }

      .iconns path {
        stroke: #FF0083 !important;
      }
    }

    .logout {
      position: absolute;
      bottom: 0;
    }
  }

  .btn-logout {
    background: #181936;
    border: 1.5px solid #F15B46;
    border-radius: 8px;
    width: 200px;
    height: 50px;
  }

  .role-fvrt path {
    fill: #767676;
  }

  .welcomeuser {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;
    z-index: 999;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 20px;

    .welcomeupperimg {
      width: 59px;
      height: 59px;
      border-radius: 120px;
      object-fit: cover;
      object-position: center;
      margin-right: 13px;

      .welcomeimg {
        width: 100%;
        height: 100%;
        border-radius: 120px;
        object-fit: cover;
        object-position: center;
      }
    }

    .welcomepara {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: #7F8486;
      margin-bottom: 3px;
    }

    .welcomehead {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #2D3436;
    }
  }

  .bottom-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 999;

    .reset-password {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      margin-bottom: 35px;
      color: #FF0083;

      img {
        margin-right: 11px;
      }
    }

    .logoutbtn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 13px 65px;
      gap: 10px;
      background: #FAFCFF;
      border: 1px solid #FE0000;
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #FE0000;

      .logoutimg {
        width: 22px;
        height: 22px;
      }
    }
  }
}


@media (max-width:600px) {
  .table thead tr th {
    padding: 15px 20px !important;
  }

  .table tbody tr td {
    padding: 15px 20px !important;
  }

  .content .table>thead>tr>th {
    padding: 15px 20px !important;
  }

  .content .table>tbody>tr>td {
    padding: 15px 20px !important;
  }
}


.modal12 {

  .mdc12 {
    border-radius: 12px;
    background: #181936;

    .head12 {
      background: #242545;
      border-radius: 12px 12px 0px 0px;
      border: none;
      padding: 22px !important;

      .modal-title {
        text-align: center;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        color: #FFFFFF;
      }

      .shut12 {
        background: #1FA5FF;
        padding: 5px 9px;
        margin-top: 1px;
        border-radius: 50px;
      }
    }

    .mdbody12 {
      background: #181936;
      padding: 50px !important;

      .form-group {
        .text123 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #FFFFFF !important;
        }

        .form-control {
          background: #1E1F3D;
          border: 1px solid #2C2D49;
          border-radius: 8px;
          color: #FFFFFF;
        }
      }
    }

    .footf11 {
      background: #181936;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn12 {
        width: 110px;
        height: 54px;
        background: #1FA5FF;
        box-shadow: 0px 3px 30px rgba(31, 165, 255, 0.2);
        border-radius: 48px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
      }

      .canclbtn {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
        color: #1FA5FF;

      }
    }

  }
}

.modal-open .modal {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
}