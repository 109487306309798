.afterconnect {
    background: url("../../assets/img/bannerbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100VH;
    display: flex;
    justify-content: center;
    align-items: center;

    .afterconnect-headings {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .afterconnecthead {
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            line-height: 100%;
            letter-spacing: -0.015em;
            color: #FF0083;
            text-align: center;
            margin-bottom: 15px;
        }

        .afterconnectpara {
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 150%;
            letter-spacing: -0.015em;
            color: #323232;
            text-align: center;
            margin-bottom: 45px;
        }

        .common-btn {
            padding: 25px 82px;
            font-weight: 600;
            font-size: 22px;
            line-height: 100%;
            color: #FFFFFF;
            box-shadow: 0px 28px 30px -10px rgba(255, 255, 255, 0.15);
            border-radius: 4px;
        }
    }
}

@media (max-width:600px) {
    .afterconnect .afterconnect-headings .afterconnecthead {
        font-size: 22px;
    }

    .afterconnect .afterconnect-headings .afterconnectpara {
        font-size: 16px;
        font-weight: 600;
    }

    .afterconnect .afterconnect-headings {
        padding-top: 160px;
        padding-bottom: 160px;
    }
    .afterconnect{
        min-height: 100vh;
    }
    .afterconnect .afterconnect-headings .common-btn{
        font-size: 16px;
        padding: 20px 60px;
        border-radius: 3px;
        width: 100%;
    }
    .afterconnect-headings{
        a{
            width: 100%;
        }
    }
}