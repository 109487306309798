.kickback {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    .loginBtn {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .parent {
        // text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .head {
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 100%;
                color: #323232;
                margin-top: 72px;
                margin-bottom: 15px;
            }

            input {
                background: #ffffff;
                border: 1.5px solid #ff80c1;
                border-radius: 5px;
                padding: 25px 20px;
                width: 557px;
                height: 70px;
                margin-bottom: 40px;
                outline: none;

                &::placeholder {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 100%;
                    color: #afafaf;
                }
            }

            .common-btn {
                padding: 25px 82px;
                width: 557px;
                height: 72px;
                border-radius: 4px;
                border: none;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 100%;
            }
        }
    }
}

@media (max-width: 600px) {
    .kickback .parent .head input {
        width: 100%;
    }

    .kickback .parent .head .common-btn {
        width: 100%;
    }

    .head {
        width: 100%;
    }
    .kickback {
        padding: 0px;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .parent {
            img {
                height: 100px;
            }
        }
    }
    .kickback .parent .head p {
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .kickback .parent .head input {
        padding: 15px 10px;
        height: unset;
        margin-bottom: 20px;
        &::placeholder {
            font-size: 16px;
        }
    }
    .kickback .parent .head .common-btn {
        font-size: 16px;
        padding: 20px;
        border-radius: 3px;
        height: unset;
    }
}
