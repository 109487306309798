.banner {
    background: url("../../assets/img/bannerbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-headings {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 700px;
        margin: 0 auto;

        .bannerhead {
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 100%;
            letter-spacing: -0.015em;
            color: #FF0083;
            text-align: center;
            margin-bottom: 15px;
        }

        .bannerpara {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 150%;
            letter-spacing: -0.015em;
            color: #323232;
            text-align: center;
        }

        .common-btn {
            width: 100%;
            padding: 25px;
            font-weight: 600;
            font-size: 22px;
            line-height: 100%;
            margin-top: 40px;
        }
    }
}

@media (max-width:600px) {
    .banner .banner-headings .bannerhead {
        font-size: 22px;
    }

    .banner .banner-headings .bannerpara {
        font-size: 16px;
        font-weight: 600;
    }

    .banner .banner-headings {
        padding-top: 160px;
        padding-bottom: 160px;
    }
}
.walletmodal {
    .modal-dialog {
        text-align: center;
        max-width: 558px;

        .modal-content {
            background: #ffffff;
            border-radius: 15px;

            .modal-header {
                position: relative;
                padding: 40px 0px;
                border-bottom: none;

                h5 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 30px;
                    color: #171515;
                    text-align: center;
                    display: block;
                    margin: 0 auto;
                }

                .btn-close {
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    right: 20px;
                    top: 20px;
                }
            }

            .modal-body {
                padding: 10px 40px 40px;

                .maincard {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .metamask {
                        background: #ffffff;
                        border-radius: 30px;
                        text-align: center;
                        height: 215px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        cursor: pointer;
                        &:hover {
                            background-color: rgba(255, 0, 131, 0.1);
                        }

                        img {
                            margin-bottom: 5px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 25px;
                            line-height: 30px;
                            text-align: center;
                            color: #171515;
                        }
                    }
                }

                .brdr {
                    border: 1px solid #eaeaea;
                    margin-top: 50px;
                    margin-bottom: 20px;
                }

                .end {
                    p {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 30px;
                        text-align: center;
                        color: #ff0083;
                    }
                }
            }
        }
    }
}