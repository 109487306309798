/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'Manrope', sans-serif;
  src: url('~assets/fonts/nucleo-icons.eot');
  src: url('~assets/fonts/nucleo-icons.eot') format('embedded-opentype'), url('~assets/fonts/nucleo-icons.woff2') format('woff2'), url('~assets/fonts/nucleo-icons.woff') format('woff'), url('~assets/fonts/nucleo-icons.ttf');
  font-weight: normal;
  font-style: normal;
}
